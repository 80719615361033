import gif from "../assets/img/notfound.gif"

const NotFoundPage = () => {
  return (
    <main className="main">
      <section className="notfound">
        <div className="container">
          <div className="notfound__wrapper">
            <h2 className="notfound__title" data-aos="fade-down">
              Упс!
            </h2>
            <p className="notfound__text" data-aos="fade-down">Cтраница не найдена</p>
            <img className="notfound__img" src={gif} alt="Псы" data-aos="zoom-in" />
            <p className="notfound__img-text" data-aos="zoom-in">Похоже, эта страница ушла в отпуск</p>
          </div>
        </div>
      </section>
    </main>
  );
};

export default NotFoundPage;
