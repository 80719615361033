import { Link } from "react-router-dom";
import logoPng from "../assets/img/logo.png";
import logoAvif from "../assets/img/logo.png";
import logoWebp from "../assets/img/logo.png";
import sprite from "../assets/img/sprite.svg";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer__logo">
          <Link to="/" className="footer__logo-link">
            <picture className="">
              <source srcSet={logoAvif} type="image/avif" />
              <source srcSet={logoWebp} type="image/webp" />
              <img className="footer__logo-img" src={logoPng} alt="Лого" />
            </picture>
          </Link>
        </div>

        <ul className="footer__lists">
          <li className="footer__item">
            <Link to="/" className="footer__item-link">
              Главная
            </Link>
          </li>

          <li className="footer__item">
            <Link to="/portfolio" className="footer__item-link">
              Работы
            </Link>
          </li>

          <li className="footer__item">
            <Link to="/about" className="footer__item-link">
              Обо мне
            </Link>
          </li>

          <li className="footer__item">
            <Link to="/contact" className="footer__item-link">
              Контакты
            </Link>
          </li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
