import links from "../data/myLinks";
import sprite from "../assets/img/sprite.svg";
import { useEffect, useRef } from "react";
import ModalContext from "../context/ModalContext";
import { useContext } from "react";

const Modal = () => {
  const { modal, showModal } = useContext(ModalContext);

  const overlayRef = useRef(null);

  useEffect(() => {
    const handlerClick = (e) => {
      if (e.composedPath()[0] === overlayRef.current) {
        showModal(false);
      }
    };

    document.body.addEventListener("click", handlerClick);

    return () => document.body.removeEventListener("click", handlerClick);
  }, []);

  return (
    <div
      className={`modal-overlay ${modal ? "modal-overlay--active" : ""}`}
      ref={overlayRef}
    >
      <div className="contact-social">
        <h3 className="contact-social__title">СОЦИАЛЬНЫЕ СЕТИ</h3>
        <div className="contact-social__items">
          <div className="contact-social__item" data-aos="zoom-in">
            <a
              className="contact-social__img-link"
              href={links.telegram}
              target="_blank"
            >
              <svg className="contact-social__img">
                <use xlinkHref={sprite + "#telegram"} />
              </svg>
            </a>
          </div>
          <div className="contact-social__item" data-aos="zoom-in">
            <a
              className="contact-social__img-link"
              href={links.instagram}
              target="_blank"
            >
              <svg className="contact-social__img">
                <use xlinkHref={sprite + "#instagram"} />
              </svg>
            </a>
          </div>
          <div className="contact-social__item" data-aos="zoom-in">
            <a
              className="contact-social__img-link"
              href={links.mail}
              target="_blank"
            >
              <svg className="contact-social__img">
                <use xlinkHref={sprite + "#mail"} />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
