import { Routes, Route } from "react-router-dom";
import { Suspense, lazy, useEffect, useState } from "react";
import "./scss/style.scss";
import NotFoundPage from "./pages/NotFoundPage";
import AOS from "aos";
import "aos/dist/aos.css";
import ModalContext from "./context/ModalContext";
import Loader from "./components/Loader";
import MainLayout from "./layouts/MainLayout";

const MainPage = lazy(() => import("./pages/MainPage"));
const AboutPage = lazy(() => import("./pages/AboutPage"));
const ContactPage = lazy(() => import("./pages/ContactPage"));
const PortfolioPage = lazy(() => import("./pages/PortfolioPage"));
const SpecializationPage = lazy(() => import("./pages/SpecializationPage"));

function App() {
  useEffect(() => {
    AOS.init({
      duration: 1500,
      once: true,
    });
  }, []);

  const [modal, showModal] = useState(false);

  return (
    <ModalContext.Provider value={{ modal, showModal }}>
      <div className={`App ${modal ? "dis-scroll" : ""}`}>
        <Routes>
          <Route path="/" element={<MainLayout />}>
            <Route
              index
              element={
                <Suspense fallback={<Loader />}>
                  <MainPage />
                </Suspense>
              }
            />
            <Route
              path="/about"
              element={
                <Suspense fallback={<Loader />}>
                  <AboutPage />
                </Suspense>
              }
            />
            <Route
              path="/contact"
              element={
                <Suspense fallback={<Loader />}>
                  <ContactPage />
                </Suspense>
              }
            />
            <Route
              path="/portfolio"
              element={
                <Suspense fallback={<Loader />}>
                  <PortfolioPage />
                </Suspense>
              }
            />
            <Route
              path="/specialization"
              element={
                <Suspense fallback={<Loader />}>
                  <SpecializationPage />
                </Suspense>
              }
            />
            <Route path="*" element={<NotFoundPage />} />
          </Route>
        </Routes>
      </div>
    </ModalContext.Provider>
  );
}

export default App;
