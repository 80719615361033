import { Link, NavLink } from "react-router-dom";
import logoPng from "../assets/img/logo.png";
import logoAvif from "../assets/img/logo.png";
import logoWebp from "../assets/img/logo.png";
import { useState } from "react";
import ModalContext from "../context/ModalContext";
import { useContext } from "react";

const Header = () => {
  const { showModal } = useContext(ModalContext);

  const [showNav, setShowNav] = useState(false);

  const handlerClick = () => setShowNav(!showNav);

  const handlerClickNavigation = () => (showNav ? setShowNav(!showNav) : null);

  return (
    <header className="header">
      <div className="container">
        <div className="header__inner">
          <div className="header__logo">
            <Link to="/" className="header__logo-link">
              <picture className="header__logo-pic">
                <source srcSet={logoAvif} type="image/avif" />
                <source srcSet={logoWebp} type="image/webp" />
                <img className="header__logo" src={logoPng} alt="Лого" />
              </picture>
            </Link>
          </div>

          <ul
            className={`header__lists ${
              showNav ? "header__lists--active" : ""
            }`}
          >
            <li className="header__item header__item--active">
              <NavLink
                to="/"
                className={({ isActive }) =>
                  isActive
                    ? "header__item-link header__item-link--active"
                    : "header__item-link"
                }
                onClick={handlerClickNavigation}
              >
                Главная
              </NavLink>
            </li>
            <li className="header__item">
              <NavLink
                to="/portfolio"
                className={({ isActive }) =>
                  isActive
                    ? "header__item-link header__item-link--active"
                    : "header__item-link"
                }
                onClick={handlerClickNavigation}
              >
                Работы
              </NavLink>
            </li>
            <li className="header__item">
              <NavLink
                to="/about"
                className={({ isActive }) =>
                  isActive
                    ? "header__item-link header__item-link--active"
                    : "header__item-link"
                }
                onClick={handlerClickNavigation}
              >
                Обо мне
              </NavLink>
            </li>
            <li className="header__item">
              <NavLink
                to="/contact"
                className={({ isActive }) =>
                  isActive
                    ? "header__item-link header__item-link--active"
                    : "header__item-link"
                }
                onClick={handlerClickNavigation}
              >
                Контакты
              </NavLink>
            </li>
          </ul>
          <button
            className="header__talk btn-reset modal-btn"
            data-graph-path="header"
            onClick={() => showModal(true)}
          >
            Связаться
          </button>
          <button className="header__btn" onClick={handlerClick}>
            <span
              className={`header__btn-line ${
                showNav ? "header__btn-line--active" : ""
              }`}
            ></span>
            <span
              className={`header__btn-line ${
                showNav ? "header__btn-line--active" : ""
              }`}
            ></span>
            <span
              className={`header__btn-line ${
                showNav ? "header__btn-line--active" : ""
              }`}
            ></span>
          </button>
        </div>
      </div>
    </header>
  );
};

export default Header;
